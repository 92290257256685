@import "_variables.scss";

.orangeText {
    color: $color-gradient-light-orange;
}

.content, .contentCenter {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 5%;
}

.contentCenter {
    text-align: center;
}

.mono {
    font-family: var(--font-roboto-mono) !important;
}

.buttonContainer {
    display: flex;
    gap: $space-rem-medium;
    margin: $space-rem-large 0;

    &.center {
        justify-content: center;
    }
}

@media screen and (min-width: $breakpoint-tablet-desktop) {
    .content, .contentCenter {
        padding: $space-rem-xlarge
    }
}
@import "_variables.scss";
@import "./css/common.scss";

$hero_offset: 30rem;
$hero_offset_mobile: 15rem;

.headline {
    background: linear-gradient(180deg, $color-font-card -96.91%, $color-default-font-color 94.1%);
    color: $color-default-white;
    padding-bottom: $hero_offset_mobile;
    position: relative;
}

.headline::after {
    background: url('../../../public/assets/images/dot-pattern-walkthrough.svg')
    no-repeat;
    background-size: cover;
    background-position: 100%;
    content: '';
    display: block;
    height: 360px;
    left: -2rem;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 87%;
}

.headlineContent {
    position: relative;
    z-index: 1;
    h2 {
        padding: $space-rem-large 0;
    }
}

.hero {
    background: url('../../../public/assets/images/orange-blocks.svg') no-repeat;
    background-position: center top;
    margin-bottom: -$hero_offset_mobile;
    position: relative;
    top: -$hero_offset_mobile;
}

.howItWorks {
    padding: $space-rem-section 0;
}

.howItWorksContent {
    display: flex;
    flex-direction: column-reverse;
    gap: $space-rem-large;
}

.howItWorksImage {
    background: url('../../../public/assets/images/teaser-howItWorksBg.svg') no-repeat;
    text-align: center;

    img {
        display: inline-block;
        max-width: calc(100% - 4rem);
    }
}

.productReviewImage {
    border-radius: $border-radius-medium;
    box-shadow: 0px 4px 14px 0px $color-box-shadow;
    margin-top: $space-rem-section;
    max-width: 50rem;
}

.exploreSandbox {
    background-color: $color-surface-blue-light;
    padding: $space-rem-section 0;
}

.exploreSandboxContent {
    display: flex;
    flex-direction: column;
    gap: $space-rem-large;

    p {
        margin: 1rem 0;
    }
    img {
        display: inline-block;
        max-width: 30%;
        margin: 0 auto;
    }
}

.discover {
    background: $color-default-font-color;
    color: $color-default-white;
    padding: $space-rem-section 0;
    text-align: center;
}

.discoverButtons {
    display: flex;
    gap: $space-rem-large;
    justify-content: center;

    &>input[type=button] {
        min-width: 25rem;
    }
}

.footer {
    background-color: $color-footer-background;
    padding: var(--chakra-space-1-6) 0;
    text-align: center;
    font-size: $font-size-xs;
    font-family: $font-family-regular;
    font-weight: $font-weight-regular;
}

@media screen and (min-width: $breakpoint-tablet-desktop) {
    .headline {
        padding-bottom: $hero_offset;
    }

    .hero {
        margin-bottom: -$hero_offset;
        top: -$hero_offset;
    }

    .howItWorks {
        background: url('../../../public/assets/images/teaser-howItWorksBg.svg') no-repeat;
        background-position: left top;
        padding: $space-rem-section $space-rem-large;
        position: relative;
    }

    .howItWorksContent {
        flex-direction: row;
        gap: $space-rem-section;
        margin: 0 auto;
        max-width: 120rem;
    }

    .howItWorksImage {
        background: none;
        margin: 0;
        max-width: 50%;
    }
    
    .exploreSandboxContent {
        flex-direction: row;
        gap: $space-rem-section;
    }
}
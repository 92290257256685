@import './common.scss';

.headlineContent {
    align-items: flex-start;
    background:
        url('../../../../public/assets/images/teaser/one-account-logo.svg') no-repeat 3rem 15rem,
        url('../../../../public/assets/images/teaser/orange-tab-down.svg') no-repeat left top,
        url('../../../../public/assets/images/dot-pattern.svg') no-repeat right -20rem;
    display: flex;
    justify-content: flex-end;
    min-height: 80rem;
    padding-top: 20rem;
}
.headlineInnerContent {
    background: rgba(248,251,255, 0.9);
    max-width: 60rem;
    padding: $space-rem-large;
    border-radius: $border-radius-medium;
}
.elevate {
    margin-top: -10rem;
}
.elevateContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $space-rem-xlarge;
}
.exclusiveFeaturesValueForCustomers {
    background: $color-surface-blue-light;
    margin-top: $space-rem-section;
    padding: $space-rem-section 0;
}
.exclusiveFeaturesContent, .valueForCustomersContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $space-rem-xlarge;

    div {
        width: 100%;
    }
}
.smartSavings {
    background:
        url('../../../../public/assets/images/dot-pattern.svg') no-repeat,
        $color-default-font-color;
    background-position: right -80rem top -30rem;
    color: $color-default-white;
    padding: $space-rem-section 0;
}
.smartSavingsContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $space-rem-xlarge;
}
.orangeDashBubble {
    background: $color-font-card;
    border: 1px dashed $color-gradient-light-orange;
    border-radius: $border-radius-medium;
    color: $color-default-white;
    padding: $space-rem-large;
}
.potentialUnlocked {
    padding: 6rem 0;
}
.potentialUnlockedInnerContent {
    &>* {
        border-top: 1px solid $color-gradient-light-orange;
        margin-top: $space-rem-large;
        padding-top: $space-rem-large;
    }
}
.potentialUnlockedButtonSection {
    border-top: 1px solid $color-gradient-light-orange;
    margin-top: $space-rem-xlarge;
    padding-top: $space-rem-xlarge;
    text-align: center;
}
.getStarted {
    background: $color-surface-blue-light;
    padding-bottom: $space-rem-section;
    img {
        display: inline-block; // Respect text align center and margin
        margin-bottom: $space-rem-xlarge;
    }

    .content {
        margin-top: 0;
        padding-top: 0;
    }
}

@media screen and (min-width: $breakpoint-tablet-desktop) {
    .elevateContent {
        align-items: flex-start;
        flex-direction: row;
    }
    .exclusiveFeaturesContent {
        flex-direction: row;
    }
    .valueForCustomersContent {
        flex-direction: row-reverse;
    }
    .smartSavingsContent {
        align-items: flex-start;
        flex-direction: row;
    }
    .potentialUnlockedInnerContent {
        display: flex;
        margin-top: $space-rem-xlarge;
    
        &>* {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
        &>:not(:first-child) {
            border-left: 1px solid $color-gradient-light-orange;
            padding-left: 2 * $space-rem-medium;
        }
        &>:not(:last-child) {
            padding-right: 2 * $space-rem-medium;
        }
    }
    .buttonBreak {
        display: none;
    }
}
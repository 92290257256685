@import '../css/common.scss';

.segmentControl {
    &>input[type=button] {
        border-radius: $border-radius-small;
        color: $color-default-font-color;
        cursor: pointer;
        font-weight: $font-weight-700;
        margin: $space-rem-medium 0;
        min-width: 12rem;
        padding: 0.875rem $space-rem-medium; 
    }
    &>input[type=button]:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    &>input[type=button]:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &>input[type=button].selected {
        background: $color-surface-text-blue;
        color: $color-default-white;
    }
}

.exploreSolutions {
    background: linear-gradient(270.1deg, rgba(251, 100, 0, 0.75) 0.09%, $color-gradient-light-orange 49.45%, #E65B00 99.91%);
    padding: $space-rem-section 0;

    h2 {
        color: $color-default-white;
    }

    p {
        margin: $space-rem-base 0;
    }

    b+p {
        margin: 0.4rem 0 $space-rem-base $space-rem-base;
    }
}

.exploreSolutionsColumns {
    border-radius: $border-radius-medium;
    box-shadow: 0px 4px 14px 0px $color-box-shadow;
    display: flex;
    flex-direction: column;
    margin-top: $space-rem-xlarge;

    &>* {
        padding: 2 * $space-rem-medium;
    }
    &>:first-child {
        background: $color-default-white;
        border-radius: $border-radius-medium $border-radius-medium 0 0;
    }
    &>:not(:first-child) {
        align-items: center;
        background: $color-default-font-color;
        border-radius: 0 0 $border-radius-medium $border-radius-medium;
        display: flex;
        justify-content: center;
    }
}

.hide {
    display: none;
}

@media screen and (min-width: $breakpoint-tablet-desktop) {
    .header {
        align-items: flex-end;
        display: flex;
        gap: $space-rem-large;
        justify-content: space-between;
    }
    .exploreSolutionsColumns {
        flex-direction: row;

        &>* {
            width: 50%;
        }
        &>:first-child {
            border-radius: $border-radius-medium 0 0 $border-radius-medium;
        }
        &>:not(:first-child) {
            border-radius: 0 $border-radius-medium $border-radius-medium 0;
        }
    }
}
@import "_variables.scss";

.listItem {
    display: flex;
    gap: 2rem;
    margin: 0 0 2rem 0;
}
.number {
    align-items: center;
    background: $color-default-black;
    border-radius: 50%;
    color: $color-default-white;
    display: flex;
    flex-basis: 3rem;
    flex-grow: 0;
    flex-shrink: 0;
    height: 3rem;
    justify-content: center;
}
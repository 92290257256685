.bulletSection {
    align-items: flex-start;
    display: flex;
    margin-top: 4rem;
    gap: 1.5rem;

    h6 {
        margin-top: 1rem;
    }
}
